import React, { useState } from "react";
import "./Homepage.css";
import Fullpage, { FullPageSections,  FullpageSection,  FullpageNavigation,} from "@ap.cx/react-fullpage";
import Navbar from "./Navbar.js";
import { Link } from "react-scroll";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import CountUp from "react-countup";
import { useInView } from "react-intersection-observer";
import {  TextField,  Button,  Container,  Stack,  colors,  Modal,} from "@mui/material";
// import question_form from './question_form.js';
import question_form from "./question_form";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { Facebook, Instagram, Twitter } from "@mui/icons-material";
import { Box } from "@mui/material";
// import { Container, Grid, Typography, Link, Box } from '@material-ui/core';
import Footer from "./Footer.js";
import ContactForm from "./Form";

import database from "../firebase";
//import  dbr  from "../firebaserestore";

import { useParams } from "react-router-dom";


const Unsubscribe = () => {
   document.title = "Unsubscribe";
  const fstyle = {
    height: "100vh",
    width: "100%",
    backgroundColor: "red",
    display: "flex",
    justifyContent: "space-between", // Add this line to separate logo and nav
    alignItems: "center", // Vertically center items
    padding: "0 20px", // Add padding for spacing
  };

  const navStyle = {
    display: "flex",
    listStyleType: "none",
    margin: 0,
    padding: 0,
  };

  const navItemStyle = {
    marginLeft: "20px", // Add space between nav items
  };

  const [ref, inView] = useInView({
    triggerOnce: true, // This ensures the callback is triggered only once when the element is in view
  });

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  /******************add satya*********************************/

  const handleSubmit = async (event) => {
    event.preventDefault();
     const data = new FormData(event.currentTarget);
      const contactRef = database.ref("contactform");

     contactRef.orderByChild('email').equalTo(data.get("email")).once('value', (snapshot) => {
        if (snapshot.exists()) {
          snapshot.forEach((childSnapshot) => {
            const key = childSnapshot.key;
            
            // Update the 'subscribe' field to 0
            contactRef.child(key).update({ subscribe: 0 });
          });
        }
      });

     contactRef
      .push({
        name: data.get("name"), 
        email: data.get("email"),
        phone: data.get("phone"),
      })
      .then(() => {
        //alert("You have successfully unsubscribed from First Connect Worldwide. Your phone number will be removed from our system within 24 to 48 hours.");
        alert("You have successfully opted out of messages from First Connect Worldwide. You will receive no further messages.");
        console.log("Unsubscribe Data successfully pushed to Firebase.");
        window.location.reload();
      })
      .catch(alert);
    console.log({
      name: data.get("name"),
      email: data.get("email"),
      phone: data.get("phone"),
    });
  };

  /******************end satya*********************************/
       
  return (    
    <div className="App">
      <Navbar hh={true} />
      <section id="home">
        <div class="parallax-container">
          <div class="parallax-content">
            <div className="tagline">
              <p>
                Navigating Excellence, Beyond Borders.
                <br />
                Your Cargo, Our Commitment.
                <br />
                Connecting Logistics To Precision.
              </p>
              <Button variant="contained" disableElevation onClick={handleOpen}>
                Get a Quote
              </Button>
              <Modal open={open} onClose={handleClose}>
                <div
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    backgroundColor: "#fff",
                    padding: "20px",
                  }}
                >
                  <ContactForm
                    where={"quote"}
                    style={{ transform: "scale(0.3)" }}
                  />
                </div>
              </Modal>
            </div>
          </div>
        </div>
      </section>

      {/* <div className='contact-div'> */}
      <section id="contact">
        {/* <FormControl> */}
        <Stack spacing={2} direction="row" sx={{ margin: 4 }}>
          <form class="uform" onSubmit={handleSubmit}>
            <Stack spacing={0} sx={{ marginBottom: 4 }}>
              <TextField
                required
                style={{ color: "#3a7590", marginBottom: 30 }}
                label="Name"
                variant="outlined"
                id="name"
                name="name"
                fullWidth // To make the input field take up the full width
              />
              <TextField
                required
                label="E-mail"
                variant="outlined"
                fullWidth
                id="email"
                name="email"
              />
            </Stack>

            <TextField
              required
              style={{ marginBottom: 30 }}
              label="Phone Number"
              variant="outlined"
              fullWidth
              id="phone"
              name="phone" // To allow multiline input for the message
              autoComplete="phone"
              type="number"
              // Specify the number of rows for the message input
            />

            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Unsubscribe
            </Button>
            <Modal open={open} onClose={handleClose}>
              <div
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  backgroundColor: "#fff",
                  padding: "20px",
                }}
              >
                <ContactForm
                  where={"quote"}
                  style={{ transform: "scale(0.3)" }}
                />
              </div>
            </Modal>
          </form>
          <div className="uquestiontag">
            <h1 style={{ color: "#6C757D" }}>Unsubscribe</h1>
            <h3 style={{ color: "#0585c1" }}>We would love to help!</h3>
            <p style={{ color: "#6C757D", fontWeight: "normal" }}>
              24 Commerce St #434, Newark, NJ 07102, United States
            </p>
          </div>
        </Stack>
        {/* </FormControl> */}
      </section>
      {/* </div> */}
      <div>
        <Footer />
      </div>
    </div>
  );
};

export default Unsubscribe;
