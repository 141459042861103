import React, { Fragment, useState } from "react";
import "./Privacypolicy.css";
import Fullpage, {
  FullPageSections,
  FullpageSection,
  FullpageNavigation,
} from "@ap.cx/react-fullpage";
import { Link } from "react-scroll";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import CountUp from "react-countup";
import { useInView } from "react-intersection-observer";
import { TextField, Container, Stack } from "@mui/material";
import Modal from "react-modal";
// import question_form from './question_form.js';
import question_form from "./question_form";
// import Typography from "@mui/material/Typography";
// import Grid from "@mui/material/Grid";
import {
  CenterFocusStrong,
  Facebook,
  Instagram,
  Twitter,
} from "@mui/icons-material";
// import { Container, Grid, Typography, Link, Box } from '@material-ui/core';
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import Navbar from "./Navbar.js";
import Footer from "./Footer.js";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import { useTheme } from "@mui/material/styles";
import SkipNextIcon from "@mui/icons-material/SkipNext";

import SkipPreviousIcon from "@mui/icons-material/SkipPrevious";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import CloseIcon from "@mui/icons-material/Close";
import DialogContentText from "@mui/material/DialogContentText";
import Slide from "@mui/material/Slide";
import {
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography,
  Button,
  Paper,
  IconButton,
} from "@mui/material";
import ContactForm from "./Form";

// const BootstrapDialog = styled(Dialog)(({ theme }) => ({
//   '& .MuiDialogContent-root': {
//     padding: theme.spacing(2),
//   },
//   '& .MuiDialogActions-root': {
//     padding: theme.spacing(1),
//   },
// }));
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};
Modal.setAppElement("body ");

const Termsofservice = () => {
   document.title = "Terms of Service";
  const theme = useTheme();
  const fstyle = {
    height: "100vh",
    width: "100%",
    backgroundColor: "red",
    display: "flex",
    justifyContent: "space-between", // Add this line to separate logo and nav
    alignItems: "center", // Vertically center items
    padding: "0 20px", // Add padding for spacing
  };

  const navStyle = {
    display: "flex",
    listStyleType: "none",
    margin: 0,
    padding: 0,
  };

  const navItemStyle = {
    marginLeft: "20px", // Add space between nav items
  };

  const [ref, inView] = useInView({
    triggerOnce: true, // This ensures the callback is triggered only once when the element is in view
  });

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCardClick = (description) => {
    alert(description);
  };

  const cardData = [
    {
      description: [
        "Experts in Drayage with understanding of local, state, and federal regulations related to trucking and transportation which includes environmental regulations, safety regulations, and compliance with hours-of-service rules for drivers.",
        "Familiarity with the operations of ports, rail yards, and intermodal terminals is crucial understanding the nderstand the layout, procedures, and protocols at these facilities to streamline cargo movement.",
      ],
    },
  ];

  return (
    <div className="App">
      <Navbar hh={false} />
      {/* <section id="services">Services</section> */}
      {/*
       <div class="parallax-container">
        <div class="parallax-content">
          <div className="tagline">          
            <p>Terms of Service</p>      
            <Modal open={open} onClose={handleClose}>
              <div
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  backgroundColor: "#fff",
                  padding: "20px",
                }}
              ></div>
            </Modal>
          </div>
        </div>
      </div>

      */}

      <Container
        maxWidth="xl"
        style={{ marginBottom: "20px", marginTop: "130px" }}
      >
        <Typography
          gutterBottom
          variant="h4"
          align="center"
          style={{ fontWeight: "bold", fontSize: "40px", color: "#0585c1" }}
        >
          Terms of Service
        </Typography>

        <div
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            flexWrap: "wrap",
          }}
        >
          {cardData.map((card, index) => (
            <Card
              key={index}
              sx={{
                // maxWidth: 400,
                flexBasis: "100%",
                // flexBasis: 'calc(33.33% - 20px)', // Adjust the basis to create equal spacing
                margin: "10px", // Add margin for spacing
                boxShadow: "0px 8px 10px rgba(0, 0, 0, 0.2)",
              }}
            >
              <CardContent>
                <Typography
                  gutterBottom
                  variant="h5"
                  component="div"
                  color="#0585C1"
                  style={{ fontWeight: "bold", fontSize: "1.5rem" }}
                >
                  {card.title}
                </Typography>
                <div class="newclass">                
                  <p>
                    Welcome to <strong>First Connect Worldwide LLC. </strong>
                    &nbsp;("<strong>First Connect</strong>", "
                    <strong>we</strong>", "<strong>us</strong>", "
                    <strong>our</strong>"). These{" "}
                    <strong>Terms of Service</strong> ("<strong>Terms</strong>",
                    "<strong>Agreement</strong>") govern your access to and use
                    of our website, platforms, and services including freight
                    brokerage, logistics coordination, and all related services
                    (the "<strong>Services</strong>"). By accessing or using our
                    Services, you agree to be bound by these Terms. If you do
                    not agree to these Terms, you may not use our Services.
                  </p>
                  <ol>
                    <li>
                      <strong> Eligibility</strong>
                    </li>
                  </ol>
                  <p>
                    To use our Services, you must be able to form legally
                    binding contracts under applicable law. By using our
                    Services, you confirm that you meet this requirement. If you
                    represent an entity, you affirm that you have the authority
                    to bind that entity to these Terms.
                  </p>
                  <ol start="2">
                    <li>
                      <strong> Scope of Services</strong>
                    </li>
                  </ol>
                  <p>
                    <strong>First Connect Worldwide LLC. </strong>provides
                    freight and logistics services, including but not limited
                    to:
                  </p>
                  <ul class="liststyle_bullat">
                    <li>Freight brokerage services</li>
                    <li>Transportation coordination</li>
                    <li>Real-time shipment tracking</li>
                    <li>Supply chain management consulting</li>
                  </ul>
                  <p>
                    Our Services are provided based on the agreements and
                    requirements outlined with each client. We do not own or
                    operate vehicles or transportation facilities, but we
                    facilitate the connection between clients and third-party
                    carriers.
                  </p>
                  <ol start="3">
                    <li>
                      <strong> Use of Services</strong>
                    </li>
                  </ol>
                  <p>By using our Services, you agree:</p>
                  <ul class="liststyle_bullat">
                    <li>
                      To provide accurate and complete information during
                      registration and service usage.
                    </li>
                    <li>
                      Not to misuse our Services, including using our platform
                      for illegal or fraudulent purposes.
                    </li>
                    <li>
                      To comply with all applicable laws, rules, and regulations
                      related to freight, transportation, and logistics when
                      using our Services.
                    </li>
                    <li>
                      To ensure that your use of our Services does not cause
                      harm or disruption to our platform, or third parties
                      involved.
                    </li>
                  </ul>
                  <p>
                    We reserve the right to limit or revoke your access to our
                    Services if these Terms are violated.
                  </p>
                  <ol start="4">
                    <li>
                      <strong> Account Registration</strong>
                    </li>
                  </ol>
                  <p>
                    To access certain Services, you may be required to create an
                    account. You are responsible for safeguarding your account
                    credentials and for all activities that occur under your
                    account. You agree to notify us immediately if you suspect
                    unauthorized use of your account.{" "}
                    <strong>First Connect Worldwide LLC. </strong>will not be
                    liable for any losses caused by unauthorized account access.
                  </p>
                  <ol start="5">
                    <li>
                      <strong> Fees and Payments</strong>
                    </li>
                  </ol>
                  <p>
                    Our fees are outlined in the specific agreements made with
                    each client. You agree to pay all applicable charges in
                    accordance with these agreements. Late payments may incur
                    penalties as described in your service contract. We reserve
                    the right to modify pricing for our Services with prior
                    notice.
                  </p>
                  <p>
                    Third-party payment processors may be used for transactions,
                    and by using our Services, you agree to their terms as well.
                  </p>
                  <ol start="6">
                    <li>
                      <strong> Intellectual Property Rights</strong>
                    </li>
                  </ol>
                  <p>
                    All content, logos, trademarks, and intellectual property
                    rights associated with our Services belong to{" "}
                    <strong>First Connect Worldwide LLC. </strong>or its
                    licensors. You are granted a limited, non-exclusive license
                    to access and use our platform for its intended purposes.
                    Any unauthorized use of our intellectual property is
                    prohibited.
                  </p>
                  <ol start="7">
                    <li>
                      <strong> User-Provided Content</strong>
                    </li>
                  </ol>
                  <p>
                    You may submit content, data, or information to us as part
                    of using our Services ("User Content"). By submitting User
                    Content, you grant{" "}
                    <strong>First Connect Worldwide LLC. </strong>a
                    non-exclusive, royalty-free license to use, display, and
                    distribute such content in connection with delivering our
                    Services. You affirm that you have the rights to share this
                    content and that it does not violate third-party rights or
                    laws.
                  </p>
                  <ol start="8">
                    <li>
                      <strong> Limitation of Liability</strong>
                    </li>
                  </ol>
                  <p>
                    To the fullest extent permitted by law,{" "}
                    <strong>First Connect Worldwide LLC. </strong>is not liable
                    for any indirect, incidental, special, or consequential
                    damages, including loss of profits, loss of data, or loss of
                    business opportunities, arising out of or in connection with
                    your use of our Services. This limitation applies even if we
                    were advised of the possibility of such damages.
                  </p>
                  <p>
                    Our total liability to you, regardless of the form of
                    action, will not exceed the amount you have paid to us for
                    the Services during the past 12 months.
                  </p>
                  <ol start="9">
                    <li>
                      <strong> Disclaimers</strong>
                    </li>
                  </ol>
                  <p>
                    Our Services are provided on an "AS IS" and "AS AVAILABLE"
                    basis. We do not guarantee that the Services will be
                    uninterrupted, secure, or free from errors. We do not make
                    any warranties regarding the accuracy or reliability of the
                    Services.
                  </p>
                  <p>
                    We are not responsible for any errors or delays caused by
                    third-party carriers, data processing issues, or events
                    outside our control (e.g., weather disruptions, strikes,
                    etc.).
                  </p>
                  <ol start="10">
                    <li>
                      <strong> Third-Party Services</strong>
                    </li>
                  </ol>
                  <p>
                    Our platform may include services or content from third
                    parties. <strong>First Connect Worldwide LLC. </strong>does
                    not control or assume responsibility for the services,
                    terms, or privacy practices of third-party providers. Your
                    interactions with third parties are governed by their
                    respective terms.
                  </p>
                  <ol start="11">
                    <li>
                      <strong> Termination of Services</strong>
                    </li>
                  </ol>
                  <p>
                    <strong>First Connect Worldwide LLC. </strong>may terminate
                    your access to our Services at any time, without notice, if
                    you breach these Terms, fail to make timely payments, or
                    engage in any illegal or harmful activities. You may also
                    terminate your use of the Services at any time by
                    discontinuing access.
                  </p>
                  <p>
                    Upon termination, any rights or obligations that should
                    reasonably survive will remain in effect, including, without
                    limitation, provisions related to intellectual property,
                    fees, and limitation of liability.
                  </p>
                  <ol start="12">
                    <li>
                      <strong> Indemnification</strong>
                    </li>
                  </ol>
                  <p>
                    You agree to indemnify, defend, and hold harmless{" "}
                    <strong>First Connect Worldwide</strong>, its affiliates,
                    employees, officers, and agents, from any claims, damages,
                    liabilities, costs, or expenses (including reasonable
                    attorneys&rsquo; fees) arising out of your use of the
                    Services, your breach of these Terms, or your violation of
                    any third-party rights.
                  </p>
                  <ol start="13">
                    <li>
                      <strong> Governing Law and Disputes</strong>
                    </li>
                  </ol>
                  <p>
                    These Terms are governed by the laws of the{" "}
                    <strong>State of New Jersey</strong>. Any disputes arising
                    out of or relating to these Terms or the use of our Services
                    shall be resolved in the state or federal courts located in{" "}
                    <strong>New Jersey</strong>, and you consent to the
                    jurisdiction of such courts.
                  </p>
                  <ol start="14">
                    <li>
                      <strong> Modifications to Terms</strong>
                    </li>
                  </ol>
                  <p>
                    We reserve the right to modify or update these Terms at any
                    time. Changes will be effective immediately upon posting on
                    our website, and your continued use of the Services will
                    constitute acceptance of the updated Terms. We will provide
                    advance notice of significant changes to these Terms.
                  </p>
                  <ol start="15">
                    <li>
                      <strong> Contact Information</strong>
                    </li>
                  </ol>
				  <p>By providing your phone number, you agree to receive a text message from First Connect Worldwide. Message and Data rates may apply, Message frequency varies. 
				  To stop receiving messages, reply 'STOP' at any time. For more information, reply 'HELP'.</p>
                  <p>
                    For any questions regarding these Terms or to request
                    support, please contact us:
                  </p>
                  <p>
                    i<strong>nfo@firstconnectworldwide.com</strong>
                    <br /> <strong>+1 908-888-0022</strong>
                    <br />{" "}
                    <a href="http://www.firstconnectworldwide.com">
                      <strong>www.firstconnectworldwide.com</strong>
                    </a>
                  </p>
                  <p>&nbsp;</p>
                </div>
              </CardContent>
            </Card>
          ))}
        </div>
      </Container>

      <div>
        <Footer />
      </div>
    </div>
  );
};
export default Termsofservice;
