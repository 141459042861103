import React, { useState } from "react";
import "./Homepage.css";
import Fullpage, { FullPageSections,  FullpageSection,  FullpageNavigation,} from "@ap.cx/react-fullpage";
import Navbar from "./Navbar.js";
import { Link } from "react-scroll";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import CountUp from "react-countup";
import { useInView } from "react-intersection-observer";
import {  TextField,  Button,  Container,  Stack,  colors,  Modal,} from "@mui/material";
// import question_form from './question_form.js';
import Checkbox from '@mui/material/Checkbox';
import question_form from "./question_form";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { Facebook, Instagram, Twitter } from "@mui/icons-material";
import { Box } from "@mui/material";
// import { Container, Grid, Typography, Link, Box } from '@material-ui/core';
import Footer from "./Footer.js";
import ContactForm from "./Form";

import database from "../firebase";
//import  dbr  from "../firebaserestore";

import { useParams } from "react-router-dom";


const Contactus = () => {
  document.title = "Contact Us";
  const fstyle = {
    height: "100vh",
    width: "100%",
    backgroundColor: "red",
    display: "flex",
    justifyContent: "space-between", // Add this line to separate logo and nav
    alignItems: "center", // Vertically center items
    padding: "0 20px", // Add padding for spacing
  };

  const navStyle = {
    display: "flex",
    listStyleType: "none",
    margin: 0,
    padding: 0,
  };

  const navItemStyle = {
    marginLeft: "20px", // Add space between nav items
  };

  const [ref, inView] = useInView({
    triggerOnce: true, // This ensures the callback is triggered only once when the element is in view
  });

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };


const [mobileNumber, setMobileNumber] = useState("");
  const [error, setError] = useState(false);
  const [helperText, setHelperText] = useState("");

  const handleChange = (e) => {
    const value = e.target.value;

    // Check for non-numeric characters
    if (!/^\d*$/.test(value)) {
      setError(true);
      setHelperText("Only numeric values are allowed.");
      return;
    }

    // Validate length
    if (value.length < 10 || value.length > 15) {
      setError(true);
      setHelperText("Mobile number must be 10-15 digits.");
    } else {
      setError(false);
      setHelperText("");
    }

    setMobileNumber(value);
  };

  /******************add satya*********************************/

  const handleSubmit = async (event) => {
    event.preventDefault();
     const data = new FormData(event.currentTarget);
     const contactRef = database.ref("contactform");
     contactRef
      .push({
			firstname: data.get("firstname"),
            lastname: data.get("lastname"),
            company: data.get("company"),
            phone: data.get("phone"),
            email: data.get("email"),
            address: data.get("address"),
            city: data.get("city"),
            state: data.get("state"),
            pickup: data.get("pickup"),
            dropoff: data.get("dropoff"),
            capacity: data.get("capacity"),
            weight: data.get("weight"),
            desc: data.get("desc"),
            subscribe: data.get("subscribe")
      })
      .then(() => {       
        alert("Thank you for contacting First Connect Worldwide, our team will get in touch with you shortly.");  
        //alert("Thank you for reaching First Connect Worldwide Team, We will get back to you shortly");            
        console.log("Data successfully pushed to Firebase.");
        window.location.reload();
      })
      .catch(alert);
    console.log({
      firstname: data.get("firstname"),
            lastname: data.get("lastname"),
            company: data.get("company"),
            phone: data.get("phone"),
            email: data.get("email"),
            address: data.get("address"),
            city: data.get("city"),
            state: data.get("state"),
            pickup: data.get("pickup"),
            dropoff: data.get("dropoff"),
            capacity: data.get("capacity"),
            weight: data.get("weight"),
            desc: data.get("desc"),
            subscribe: data.get("subscribe")
    });
  };

  /******************end satya*********************************/
       
  return (    
    <div className="App">
      <Navbar hh={true} />
      <section id="home">
        <div class="parallax-container">
          <div class="parallax-content">
            <div className="tagline">
              <p>
                Navigating Excellence, Beyond Borders.
                <br />
                Your Cargo, Our Commitment.
                <br />
                Connecting Logistics To Precision.
              </p>
              <Button variant="contained" disableElevation onClick={handleOpen}>
                Get a Quote
              </Button>
              <Modal open={open} onClose={handleClose}>
                <div
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    backgroundColor: "#fff",
                    padding: "20px",
                  }}
                >
                  <ContactForm
                    where={"quote"}
                    style={{ transform: "scale(0.3)" }}
                  />
                </div>
              </Modal>
            </div>
          </div>
        </div>
      </section>

      {/* <div className='contact-div'> */}
      <section id="contact" >
        {/* <FormControl> */}
        <Stack spacing={2} direction="row" sx={{ margin: 4 }}>
			{/* <Box  component="form" noValidate  onSubmit={handleSubmit}  sx={{ mt: 0 }} >*/}
			 <div className="contacttag">   </div>
		   <form class="uform" onSubmit={handleSubmit}>
             <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    fullWidth
                    id="firstname"
                    label="First Name"
                    name="firstname"
                    autoComplete="firstname"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    fullWidth
                    id="lastname"
                    label="Last Name"
                    name="lastname"
                    autoComplete="lastname"
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    fullWidth
                    id="company"
                    label="Company"
                    name="company"
                    autoComplete="company"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField                   
                    fullWidth
                    id="phone"
                    label="Phone"
                    name="phone"
					type="number"	
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    id="email"
                    label="Email"
                    name="email"
                    autoComplete="email"
					type="email"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField                    
                    fullWidth
                    id="address"
                    label="Address"
                    name="address"
                    autoComplete="address"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField                    
                    fullWidth
                    id="city"
                    label="City"
                    name="city"
                    autoComplete="city"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    
                    fullWidth
                    id="state"
                    label="State"
                    name="state"
                    autoComplete="state"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    
                    fullWidth
                    id="pickup"
                    label="Pickup"
                    name="pickup"
                    autoComplete="pickup"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField                    
                    fullWidth
                    id="dropoff"
                    label="Dropoff"
                    name="dropoff"
                    autoComplete="dropoff"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField                    
                    fullWidth
                    id="capacity"
                    label="Capacity"
                    name="capacity"
                    autoComplete="capacity"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField                    
                    fullWidth
                    id="weight"
                    label="Weight"
                    name="weight"
                    autoComplete="weight"
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <TextField                    
                    fullWidth
                    id="desc"
                    label="Description"
                    name="desc"
                    autoComplete="desc"
                  />
                </Grid>          
				 <Grid item xs={2} sm={2} > 				  
				  <Checkbox
                    required
                    fullWidth
                    id="subscribe"
                    label="Subscribe"
                    name="subscribe"
                    autoComplete="subscribe"
                    value="1"
                  />  </Grid>
				  <Grid item xs={10} sm={10}> 
				  <p className="textcontant">
				  By providing your phone number, you agree to receive a text message from First Connect Worldwide. Message and Data rates may apply, 
				  Message frequency varies. To stop receiving messages, reply 'STOP' at any time. For more information, reply 'HELP' ,  
				   <a href="/privacypolicy" style={{color: "#0585c1",textDecoration: "none"}} >Privacy Policy  </a> & 
				   <a href="/termsofservice" style={{color: "#0585c1",textDecoration: "none"}} >Terms n Conditions </a>
				   </p>
				   </Grid>
                </Grid>                
            
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Submit
              </Button>
              <Grid container justifyContent="flex-end">
                <Grid item></Grid>
              </Grid>
			 </form>  
			 <div className="contacttag">   </div>
			 {/*</Box>   */} 
        </Stack>
        {/* </FormControl> */}
      </section>
      {/* </div> */}
      <div>
        <Footer />
      </div>
    </div>
  );
};

export default Contactus;
