import React, { Fragment, useState } from "react";
import "./Privacypolicy.css";
import Fullpage, {
  FullPageSections,
  FullpageSection,
  FullpageNavigation,
} from "@ap.cx/react-fullpage";
import { Link } from "react-scroll";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import CountUp from "react-countup";
import { useInView } from "react-intersection-observer";
import { TextField, Container, Stack } from "@mui/material";
import Modal from "react-modal";
// import question_form from './question_form.js';
import question_form from "./question_form";
// import Typography from "@mui/material/Typography";
// import Grid from "@mui/material/Grid";
import {
  CenterFocusStrong,
  Facebook,
  Instagram,
  Twitter,
} from "@mui/icons-material";
// import { Container, Grid, Typography, Link, Box } from '@material-ui/core';
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import Navbar from "./Navbar.js";
import Footer from "./Footer.js";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import { useTheme } from "@mui/material/styles";
import SkipNextIcon from "@mui/icons-material/SkipNext";

import SkipPreviousIcon from "@mui/icons-material/SkipPrevious";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import CloseIcon from "@mui/icons-material/Close";
import DialogContentText from "@mui/material/DialogContentText";
import Slide from "@mui/material/Slide";
import {
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography,
  Button,
  Paper,
  IconButton,
} from "@mui/material";
import ContactForm from "./Form";

// const BootstrapDialog = styled(Dialog)(({ theme }) => ({
//   '& .MuiDialogContent-root': {
//     padding: theme.spacing(2),
//   },
//   '& .MuiDialogActions-root': {
//     padding: theme.spacing(1),
//   },
// }));
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};
Modal.setAppElement("body ");

const Privacypolicy = () => {
  document.title = "Privacy Policy";
  const theme = useTheme();
  const fstyle = {
    height: "100vh",
    width: "100%",
    backgroundColor: "red",
    display: "flex",
    justifyContent: "space-between", // Add this line to separate logo and nav
    alignItems: "center", // Vertically center items
    padding: "0 20px", // Add padding for spacing
  };

  const navStyle = {
    display: "flex",
    listStyleType: "none",
    margin: 0,
    padding: 0,
  };

  const navItemStyle = {
    marginLeft: "20px", // Add space between nav items
  };

  const [ref, inView] = useInView({
    triggerOnce: true, // This ensures the callback is triggered only once when the element is in view
  });

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCardClick = (description) => {
    alert(description);
  };

  const cardData = [
    {
      description: [
        "Experts in Drayage with understanding of local, state, and federal regulations related to trucking and transportation which includes environmental regulations, safety regulations, and compliance with hours-of-service rules for drivers.",
        "Familiarity with the operations of ports, rail yards, and intermodal terminals is crucial understanding the nderstand the layout, procedures, and protocols at these facilities to streamline cargo movement.",
      ],
    },
  ];

  return (
    <div className="App">
      <Navbar hh={false} />

      {/* <section id="services">Services</section> */}

      {/*  <div class="parallax-container">
        <div class="parallax-content">
          <div className="tagline">
            <p>
              Privacy Policy.
              <br />
            </p>
            <Modal open={open} onClose={handleClose}>
              <div
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  backgroundColor: "#fff",
                  padding: "20px",
                }}
              ></div>
            </Modal>
          </div>
        </div>
      </div>*/}

      <Container
        maxWidth="xl"
        style={{ marginBottom: "20px", marginTop: "130px" }}
      >
        <Typography
          gutterBottom
          variant="h4"
          align="center"
          style={{ fontWeight: "bold", fontSize: "40px", color: "#0585c1" }}
        >
          Privacy Policy
        </Typography>

        <div
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            flexWrap: "wrap",
          }}
        >
          {cardData.map((card, index) => (
            <Card
              key={index}
              sx={{
                // maxWidth: 400,
                flexBasis: "100%",
                // flexBasis: 'calc(33.33% - 20px)', // Adjust the basis to create equal spacing
                margin: "10px", // Add margin for spacing
                boxShadow: "0px 8px 10px rgba(0, 0, 0, 0.2)",
              }}
            >
              <CardContent>
                <Typography
                  gutterBottom
                  variant="h5"
                  component="div"
                  color="#0585C1"
                  style={{ fontWeight: "bold", fontSize: "1.5rem" }}
                >
                  {card.title}
                </Typography>
                <div class="newclass">
                  {/* <p>
                    <strong>Privacy Policy</strong>
                  </p>*/}
                  <p>
                    <strong>Effective Date:</strong>15<sup>th</sup> October 2024
                  </p>
                  <p>
                    <strong>First Connect Worldwide</strong> ("
                    <strong>First Connect</strong>", "<strong>we</strong>", "
                    <strong>us</strong>", "<strong>our</strong>") is committed
                    to protecting your privacy. This Privacy Policy outlines how
                    we collect, use, disclose, and safeguard your information
                    when you use our website and services, including freight
                    brokerage, logistics coordination, and transportation
                    services (collectively, the "<strong>Services</strong>"). By
                    using our Services, you agree to the collection and use of
                    information in accordance with this policy.
                  </p>
                  <p>&nbsp;</p>
                  <ol>
                    <li>
                      <strong> Information We Collect</strong>
                    </li>
                  </ol>
                  <p>
                    We collect different types of information to provide and
                    improve our Services:
                  </p>
                  <ol class="liststyle">
                    <li>
                      <strong> a. Personal Information</strong>
                    </li>
                  </ol>
                  <p>
                    When you use our Services or register for an account, we may
                    collect personal information, including but not limited to:
                  </p>
                  <ul class="liststyle_bullat">
                    <li>Name</li>
                    <li>Email address</li>
                    <li>Phone number</li>
                    <li>Shipping and billing addresses</li>
                    <li>Payment information</li>
                  </ul>
                  <ol class="liststyle">
                    <li>
                      <strong> b. Non-Personal Information</strong>
                    </li>
                  </ol>
                  <p>We may collect non-personal information such as:</p>
                  <ul class="liststyle_bullat">
                    <li>IP address</li>
                    <li>Browser type and version</li>
                    <li>Operating system</li>
                    <li>
                      Usage data, including time spent on pages, clicks, and
                      other website analytics
                    </li>
                  </ul>
                  <ol class="liststyle">
                    <li>
                      <strong> c. Information from Third Parties</strong>
                    </li>
                  </ol>
                  <p>
                    We may also collect information about you from third
                    parties, including business partners and carriers.
                  </p>
                  <ol start="2">
                    <li>
                      <strong> How We Use Your Information</strong>
                    </li>
                  </ol>
                  <p>
                    We use your information to provide, maintain, and improve
                    our Services, including:
                  </p>
                  <ul class="liststyle_bullat">
                    <li>
                      <strong>Service Delivery:</strong> To manage and fulfill
                      logistics, freight brokerage, and transportation requests.
                    </li>
                    <li>
                      <strong>Account Management:</strong> To create, maintain,
                      and secure your account.
                    </li>
                    <li>
                      <strong>Payment Processing:</strong> To process
                      transactions and manage billing.
                    </li>
                    <li>
                      <strong>Communication:</strong> To contact you regarding
                      updates, services, and inquiries.
                    </li>
                    <li>
                      <strong>Legal Compliance:</strong> To comply with legal
                      obligations and protect our rights.
                    </li>
                  </ul>
                  <p>
                    <strong>&nbsp;</strong>
                  </p>
                  <ol start="3">
                    <li>
                      <strong> Sharing Your Information</strong>
                    </li>
                  </ol>
                  <p>
                    We do not sell your personal information to third parties.
                    However, we may share your information under the following
                    circumstances:
                  </p>
                  <ol>
                    <li>
                      <strong> Service Providers</strong>
                    </li>
                  </ol>
                  <p>
                    We may share information with third-party vendors,
                    contractors, or service providers who assist in providing
                    our Services, such as payment processors, shipping partners,
                    and IT support.
                  </p>
                  <ol>
                    <li>
                      <strong> Legal Requirements</strong>
                    </li>
                  </ol>
                  <p>
                    We may disclose your personal information if required to do
                    so by law, or in response to valid requests by public
                    authorities (e.g., a court or government agency).
                  </p>
                  <ol>
                    <li>
                      <strong> Business Transfers</strong>
                    </li>
                  </ol>
                  <p>
                    If <strong>First Connect Worldwide</strong> is involved in a
                    merger, acquisition, or asset sale, your personal
                    information may be transferred. We will provide notice
                    before your information is transferred and becomes subject
                    to a different privacy policy.
                  </p>
                  <p>
                    <strong>&nbsp;</strong>
                  </p>
                  <ol start="4">
                    <li>
                      <strong> Data Security</strong>
                    </li>
                  </ol>
                  <p>
                    We take data security seriously and use appropriate measures
                    to protect your personal information. This includes:
                  </p>
                  <ul class="liststyle_bullat">
                    <li>
                      <strong>Encryption:</strong> We use encryption to protect
                      sensitive data, such as payment information.
                    </li>
                    <li>
                      <strong>Access Controls:</strong> Only authorized
                      personnel have access to personal information.
                    </li>
                    <li>
                      <strong>Regular Audits:</strong> We conduct security
                      audits to ensure the ongoing protection of your data.
                    </li>
                  </ul>
                  <p>
                    However, no method of transmission over the Internet or
                    electronic storage is 100% secure. While we strive to use
                    commercially acceptable means to protect your personal
                    information, we cannot guarantee its absolute security.
                  </p>
                  <p>
                    <strong>&nbsp;</strong>
                  </p>
                  <ol start="5">
                    <li>
                      <strong> Cookies and Tracking Technologies</strong>
                    </li>
                  </ol>
                  <p>
                    We use cookies and similar tracking technologies to enhance
                    your browsing experience and analyze site traffic. Cookies
                    are small data files stored on your device that help us
                    remember your preferences and track your interactions on our
                    website.
                  </p>
                  <ol>
                    <li>
                      <strong> Types of Cookies We Use</strong>
                    </li>
                  </ol>
                  <ul class="liststyle_bullat">
                    <li>
                      <strong>Essential Cookies:</strong> Necessary for the
                      functioning of our website and Services.
                    </li>
                    <li>
                      <strong>Analytics Cookies:</strong> Help us understand how
                      visitors use our website to improve user experience.
                    </li>
                    <li>
                      <strong>Marketing Cookies:</strong> Track your preferences
                      to provide you with relevant marketing and advertisements.
                    </li>
                  </ul>
                  <p>
                    You can control your cookie preferences through your browser
                    settings. However, disabling cookies may limit the
                    functionality of our website.
                  </p>
                  <p>
                    <strong>&nbsp;</strong>
                  </p>
                  <ol start="6">
                    <li>
                      <strong> Your Rights</strong>
                    </li>
                  </ol>
                  <p>
                    Depending on your location, you may have certain rights
                    regarding your personal information. These rights may
                    include:
                  </p>
                  <ul class="liststyle_bullat">
                    <li>
                      <strong>Access:</strong> You can request a copy of the
                      personal information we hold about you.
                    </li>
                    <li>
                      <strong>Correction:</strong> You can request to update or
                      correct your personal information.
                    </li>
                    <li>
                      <strong>Deletion:</strong> You can request the deletion of
                      your personal information, subject to certain legal
                      obligations.
                    </li>
                    <li>
                      <strong>Opt-Out:</strong> You can opt-out of receiving
                      promotional communications from us.
                    </li>
                  </ul>
                  <p>
                    To exercise any of these rights, please contact us at{" "}
                    <a href="mailto:info@firstconnectworldwide.com">
                      <strong>info@firstconnectworldwide.com</strong>
                    </a>
                  </p>
                  <p>&nbsp;</p>
                  <ol start="7">
                    <li>
                      <strong> Retention of Information</strong>
                    </li>
                  </ol>
                  <p>
                    We retain your personal information for as long as necessary
                    to provide our Services and comply with legal obligations.
                    When we no longer need your information, we will take steps
                    to delete or anonymize it.
                  </p>
                  <p>
                    <strong>&nbsp;</strong>
                  </p>
                  <ol start="8">
                    <li>
                      <strong> Third-Party Links</strong>
                    </li>
                  </ol>
                  <p>
                    Our website may contain links to third-party websites or
                    services that are not operated by us. We are not responsible
                    for the content, privacy policies, or practices of these
                    third-party websites. We encourage you to review the privacy
                    policies of any third-party sites you visit.
                  </p>
                  <p>
                    <strong>&nbsp;</strong>
                  </p>
                  <ol start="9">
                    <li>
                      <strong> Children's Privacy</strong>
                    </li>
                  </ol>
                  <p>
                    Our Services are not directed to individuals under the age
                    of 18. We do not knowingly collect personal information from
                    children. If you are a parent or guardian and believe that
                    your child has provided us with personal information, please
                    contact us, and we will take steps to remove that
                    information from our servers.
                  </p>
                  <p>
                    <strong>&nbsp;</strong>
                  </p>
                  <ol start="10">
                    <li>
                      <strong> Changes to This Privacy Policy</strong>
                    </li>
                  </ol>
                  <p>
                    We may update this Privacy Policy from time to time. Any
                    changes will be posted on this page with an updated
                    effective date. We encourage you to review this Privacy
                    Policy periodically for any changes.
                  </p>
                  <p>
                    <strong>&nbsp;</strong>
                  </p>
                  <ol start="11">
                    <li>
                      <strong> Contact Us</strong>
                    </li>
                  </ol>
                  <p>
                    Privacy Policies are examined to confirm that consumer data
                    is not being transferred across different organizations for
                    unauthorized purposes. To meet these requirements
                    successfully, we strongly recommend that senders adopt and
                    explicitly outline a process within their Privacy Policy,
                    clearly stating their commitment to not sharing consumer
                    data with third parties for marketing or other purposes.
                  </p>
				   <p>
				   Mobile information will not be shared with third parties/affiliates marketing/promotional purposes. 
				   All the above categories exclude text messaging originator opt-in data and consent; this information will not be shared with any third parties.
				  </p>
				  <p>If you wish to be removed from receiving future communications, you can opt out by texting STOP, QUIT.</p>
                  <p>
                    If you have any questions or concerns about this Privacy
                    Policy or our data practices, please contact us:
                  </p>
                  <p>
                    <strong>info@firstconnectworldwide.com</strong>
                    <br /> <strong>+1908-888-0022</strong>
                    <br />{" "}
                    <a href="http://www.firstconnectworldwide.com">
                      <strong>www.firstconnectworldwide.com</strong>
                    </a>
                  </p>
                  <p>&nbsp;</p>
                </div>
              </CardContent>
            </Card>
          ))}
        </div>
      </Container>

      <div>
        <Footer />
      </div>
    </div>
  );
};
export default Privacypolicy;
