import React, { useState } from "react";
import "./Homepage.css";
import Fullpage, { FullPageSections,  FullpageSection,  FullpageNavigation,} from "@ap.cx/react-fullpage";
import Navbar from "./Navbar.js";
import { Link } from "react-scroll";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import CountUp from "react-countup";
import { useInView } from "react-intersection-observer";
import {  TextField,  Button,  Container,  Stack,  colors,  Modal,} from "@mui/material";
// import question_form from './question_form.js';
import Checkbox from '@mui/material/Checkbox';
import question_form from "./question_form";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { Facebook, Instagram, Twitter } from "@mui/icons-material";
import { Box } from "@mui/material";
// import { Container, Grid, Typography, Link, Box } from '@material-ui/core';
import Footer from "./Footer.js";
import ContactForm from "./Form";

import database from "../firebase";
//import  dbr  from "../firebaserestore";

import { useParams } from "react-router-dom";


const Careers = () => {
  document.title = "Careers";
  const fstyle = {
    height: "100vh",
    width: "100%",
    backgroundColor: "red",
    display: "flex",
    justifyContent: "space-between", // Add this line to separate logo and nav
    alignItems: "center", // Vertically center items
    padding: "0 20px", // Add padding for spacing
  };

  const navStyle = {
    display: "flex",
    listStyleType: "none",
    margin: 0,
    padding: 0,
  };

  const navItemStyle = {
    marginLeft: "20px", // Add space between nav items
  };

  const [ref, inView] = useInView({
    triggerOnce: true, // This ensures the callback is triggered only once when the element is in view
  });

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [mobileNumber, setMobileNumber] = useState("");
  const [error, setError] = useState(false);
  const [helperText, setHelperText] = useState("");

  const handleChange = (e) => {
    const value = e.target.value;

    // Check for non-numeric characters
    if (!/^\d*$/.test(value)) {
      setError(true);
      setHelperText("Only numeric values are allowed.");
      return;
    }

    // Validate length
    if (value.length < 10 || value.length > 15) {
      setError(true);
      setHelperText("Mobile number must be 10-15 digits.");
    } else {
      setError(false);
      setHelperText("");
    }

    setMobileNumber(value);
  };

  /******************add satya*********************************/

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const contactRef = database.ref("jobApplications");
    contactRef
      .push({
        firstname: data.get("firstname"),
        lastname: data.get("lastname"),
        company: data.get("company"),
        phone: data.get("phone"),
        email: data.get("email"), // Use the email variable directly
        address: data.get("address"),
        city: data.get("city"),
        state: data.get("state"),
        linkedInUrl: data.get("linkedInUrl"),
        education: data.get("education"),
        personalNote: data.get("personalNote"),
      })
      .then(() => {
        alert("Thank you for contacting First Connect Worldwide, our team will get in touch with you shortly." );
        //alert("Thank you for reaching First Connect Worldwide Team, We will get back to you shortly");
        console.log("Data successfully pushed to Firebase.");
        window.location.reload();
      })
      .catch(alert);
    console.log({
      firstname: data.get("firstname"),
      lastname: data.get("lastname"),
      company: data.get("company"),
      phone: data.get("phone"),
      email: data.get("email"), // Use the email variable directly
      address: data.get("address"),
      city: data.get("city"),
      state: data.get("state"),
      linkedInUrl: data.get("linkedInUrl"),
      education: data.get("education"),
      personalNote: data.get("personalNote"),
    });
  };

  /******************end satya*********************************/

  return (
    <div className="App">
      <Navbar hh={true} />
      <section style={{ height: "15vh" }}></section>

      {/* <div className='contact-div'> */}
      <section id="contact">
        {/* <FormControl> */}
        <Stack spacing={2} direction="row" sx={{ margin: 4 }}>
          {/* <Box  component="form" noValidate  onSubmit={handleSubmit}  sx={{ mt: 0 }} >*/}
          <div className="contacttag"> </div>

          <form class="uform" onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  id="firstname"
                  label="First Name"
                  name="firstname"
                  autoComplete="firstname"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  id="lastname"
                  label="Last Name"
                  name="lastname"
                  autoComplete="lastname"
                />
              </Grid>

              <Grid item xs={12} sm={12}>
                <TextField
                  required
                  fullWidth
                  id="company"
                  label="Company"
                  name="company"
                  autoComplete="company"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="phone"
                  label="Phone"
                  name="phone"
                  autoComplete="phone"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="email"
                  label="Email"
                  name="email"
                  autoComplete="email"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  id="address"
                  label="Address"
                  name="address"
                  autoComplete="address"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  id="city"
                  label="City"
                  name="city"
                  autoComplete="city"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  id="state"
                  label="State"
                  name="state"
                  autoComplete="state"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  id="linekdInUrl"
                  label="Linkedin Url"
                  name="linkedInUrl"
                  autoComplete="linkedInUrl"
                />
              </Grid>
            </Grid>

            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Submit
            </Button>
            <Grid container justifyContent="flex-end">
              <Grid item></Grid>
            </Grid>
          </form>

          
          <div className="contacttag"> </div>
          {/*</Box>   */}
        </Stack>
        {/* </FormControl> */}
      </section>
      {/* </div> */}
      <div>
        <Footer />
      </div>
    </div>
  );
};

export default Careers;
